import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { object, string, number } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
} from "@/components/ui/form.jsx";
import { Input } from "@/components/ui/input.jsx";
import { Button } from "@/components/ui/button.jsx";
import {
	Select,
	SelectTrigger,
	SelectValue,
	SelectContent,
	SelectItem,
} from "@/components/ui/select.jsx";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from "@/components/ui/breadcrumb";
import { useAllFetch } from "components/CustomHooks/useAllFetch";
import { useAuth } from "@clerk/clerk-react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useMutation } from "@tanstack/react-query";
import { Loading } from "../UI/Loading";
import ip from "../../config_ip";

const EditJob = ({ ronly }) => {
	const [address, setAddress] = useState("");
	const [setLat] = useState("");
	const [setLng] = useState("");
	const [setZipCode] = useState("");
	const [setProvince] = useState("");

	const { getToken } = useAuth();
	const jobId = window.location.pathname.split('/')[2];
	const contractorId = localStorage.getItem('contractor_id');

	const { data: jobData, isPending: jobLoading } = useAllFetch(['job'], `/getjob/${jobId}`);
	const { data: insurances, isPending: insurancesLoading } = useAllFetch(['insurances'], '/insurances');

	const form = useForm({
		resolver: yupResolver(object().shape({
			description: string().required(),
			causeloss: string().max(500, "Too Long!").required(),
			address: string().required(),
			postalcode: string().required(),
			insuranceid: number().required(),
			claimnumber: ronly && jobData?.insuranceid === 7 ? string().matches(/^\d{4}[a-zA-Z]\d{4}$|^\d{10}$/) : null,
			adjuster_id: ronly ? number().moreThan(0) : number(),
		})),
		defaultValues: {}, // Leave this empty initially
	});

	// Reset form with the fetched jobData
	useEffect(() => {
		if (jobData) {
			form.reset({
				address: jobData?.address || "",
				description: jobData?.description || "",
				postalcode: jobData?.postalcode || "",
				claimnumber: jobData?.claimnumber || "",
				insuranceid: jobData?.insuranceid?.toString() || "",
				contractor_id: jobData?.contractor_id?.toString() || "",
				lat: jobData?.lat || "",
				lng: jobData?.lng || "",
				adjuster_id: jobData?.adjuster_id?.toString() || "",
				user: jobData?.user || "",
				causeloss: jobData?.causeloss || "",
				province: jobData?.province || "",
				id: jobData?.id?.toString() || "",
			});
		}
	}, [jobData, form]);

	const selectedInsuranceId = form.watch('insuranceid');

	// Fetch adjusters based on selected insuranceid
	const { data: adjustersData, isPending: adjustersLoading } = useAllFetch(
		selectedInsuranceId ? ['adjusters', selectedInsuranceId] : null,
		selectedInsuranceId ? `/adjusters/${selectedInsuranceId}` : '',
		{
		  enabled: !!selectedInsuranceId, // Ensures the query is only enabled when there's a valid insuranceid
		}
	  );
	
	  const adjusters = adjustersData || [];

	const handleSelectAddress = async (selectedAddress) => {
		setAddress(selectedAddress);
		const results = await geocodeByAddress(selectedAddress);
		const { lat, lng } = await getLatLng(results[0]);
		setLat(lat);
		setLng(lng);

		const lastComponent = results[0].address_components.pop();
		setZipCode(lastComponent.long_name.replace(' ', ''));
		setProvince(results[0].address_components.slice(-2, -1)[0].short_name);
	};

	const mutation = useMutation({
		mutationFn: async (data) => {
			const token = await getToken();
			const response = await fetch(`${ip.url}/updateJob/${jobId}`, {
				method: "PUT",
				body: JSON.stringify(data),
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json",
					"Authorization": `Bearer ${token}`,
				},
			});
			return response.json();
		},
		onSuccess: async () => {
			if (ronly) {
				const token = await getToken();
				const addressShort = form.getValues("address").split(',')[0].replace('#', '');
				await fetch(ip.url + '/send-email/' + jobId + '/0/' + addressShort + '/' + adjusters[0].username + '/R/null', {
					method: "GET",
					headers: {
						"Accept": "application/json",
						"Content-Type": "application/json",
						"Authorization": `Bearer ${token}`,
					},
				});
				window.location.pathname = '/certificate2/' + jobId
			} else {
				window.location.pathname = '/jobs/' + contractorId;
			}
		},
	});

	const onSubmit = (data) => {
		mutation.mutate(data);
	};

	if (jobLoading || insurancesLoading || adjustersLoading) {
		return <Loading />;
	}

	return (
		<div className="container">
			<div className="mb-3">
				<Breadcrumb>
					<BreadcrumbList>
						<BreadcrumbItem>
							<BreadcrumbLink href={`/jobs/${contractorId}`}>Claims</BreadcrumbLink>
						</BreadcrumbItem>
						<BreadcrumbSeparator />
						<BreadcrumbItem>
							<BreadcrumbLink href={window.location.href}>Edit Claim</BreadcrumbLink>
						</BreadcrumbItem>
					</BreadcrumbList>
				</Breadcrumb>
			</div>
			{(ronly === false || ronly === undefined) &&
				<PlacesAutocomplete
					value={address}
					onChange={setAddress}
					onSelect={handleSelectAddress}
					searchOptions={{ componentRestrictions: { country: ["ca", "us"] } }}
				>
					{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
						<div>
							<input
								{...getInputProps({
									placeholder: 'Search Address ...',
									className: 'location-search-input'
								})}
								autoFocus={true}
							/>
							<div className="autocomplete-dropdown-container">
								{loading && <div>Loading ...</div>}
								{suggestions.map((suggestion, index) => {
									const className = suggestion.active ? 'suggestion-item-active' : 'suggestion-item';
									const style = suggestion.active ? { backgroundColor: '#65bdb1', cursor: 'pointer' } : { backgroundColor: '#ffffff', cursor: 'pointer' };
									return (
										<div {...getSuggestionItemProps(suggestion, { className, style })} key={index}>
											<span>{suggestion.description}</span>
										</div>
									);
								})}
							</div>
						</div>
					)}
				</PlacesAutocomplete>
			}
			
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)}>
					<FormField control={form.control} name="address" render={({ field }) => (
						<FormItem>
							<FormLabel>Address</FormLabel>
							<FormControl>
								<Input {...field} readOnly />
							</FormControl>
						</FormItem>
					)} />
					<FormField control={form.control} name="description" render={({ field }) => (
						<FormItem>
							<FormLabel>Building Description</FormLabel>
							<FormControl>
								<Input {...field} readOnly={ronly} />
							</FormControl>
						</FormItem>
					)} />
					<FormField control={form.control} name="causeloss" render={({ field }) => (
						<FormItem>
							<FormLabel>Cause of Loss</FormLabel>
							<FormControl>
								<Input {...field} readOnly={ronly} />
							</FormControl>
						</FormItem>
					)} />
					<FormField control={form.control} name="postalcode" render={({ field }) => (
						<FormItem>
							<FormLabel>Postal Code</FormLabel>
							<FormControl>
								<Input {...field} readOnly={ronly} />
							</FormControl>
						</FormItem>
					)} />
					<FormField control={form.control} name="insuranceid" render={({ field }) => (
						<FormItem>
							<FormLabel>Insurances</FormLabel>
							<FormControl>
								<Select onValueChange={(value) => form.setValue('insuranceid', value)} value={field.value}>
									<SelectTrigger>
										<SelectValue placeholder='Select Insurance name' />
									</SelectTrigger>
									<SelectContent>
										{insurances?.map((insurance) => (
											<SelectItem key={insurance.id} value={insurance.id.toString()}>
												{insurance.insurancename}
											</SelectItem>
										))}
									</SelectContent>
								</Select>
							</FormControl>
						</FormItem>
					)} />
					{ronly && (
						<FormField control={form.control} name="claimnumber" render={({ field }) => (
							<FormItem>
								<FormLabel>Claim Number</FormLabel>
								<FormControl>
									<Input {...field} placeholder={selectedInsuranceId === 7 ? "Enter Claim Number NNNNXNNNN or NNNNNNNNNN" : "Enter Claim Number"} />
								</FormControl>
							</FormItem>
						)} />
					)}
					{ronly && (
						<FormField control={form.control} name="adjuster_id" render={({ field }) => (
							<FormItem>
								<FormLabel>Adjusters</FormLabel>
								<FormControl>
									<Select onValueChange={field.onChange} value={field.value}>
										<SelectTrigger>
											<SelectValue placeholder='Select Adjuster name' />
										</SelectTrigger>
										<SelectContent>
											{adjusters.length > 0 ? (
												adjusters.map((adjuster) => (
													<SelectItem key={adjuster.id} value={adjuster.id.toString()}>
														{adjuster.aname}
													</SelectItem>
												))
											) : (
												// Disable the Select when no adjusters are available
												<SelectItem key="no-adjusters" value="no-adjusters" disabled>
													No Adjusters Available
												</SelectItem>
											)}
										</SelectContent>
									</Select>
								</FormControl>
							</FormItem>
						)} />
					)}
					<Button className="mt-2" type="submit">Save</Button>
				</form>
			</Form>
		</div>
	);
};

export default EditJob;